import { getFlatNumber } from '@labServices/utils/cmnHelper';
import useAnalytics from './useAnalytics';

const useLabTestPixalEvent = () => {
	const { fbEvent, firebaseEvent, claverTapEvent } = useAnalytics();

	const LabTestAddToCartEvent = (payload) => {
		const { testData, patientCount } = payload;

		fbEvent('AddToCart', {
			content_ids: [testData?.id.toString()],
			content_name: testData?.name?.en,
			content_type: 'web_lab_test',
			contents: [
				{
					id: testData?.id.toString(),
					quantity: patientCount,
					...testData
				}
			],
			currency: 'BDT',
			value: getFlatNumber(testData?.discountPrice)
		});

		firebaseEvent('add_to_cart', {
			content_id: testData?.id.toString(),
			content_name: testData?.name?.en,
			content_type: 'web_lab_test',
			items: [
				{
					item_id: testData?.id,
					item_name: testData?.name?.en,
					price: getFlatNumber(testData?.discountPrice)
				}
			],
			currency: 'BDT',
			value: getFlatNumber(testData?.discountPrice)
		});

		claverTapEvent('ACT_ADD_TO_CART', {
			content_type: 'web_lab_test',
			product_id: testData?.id,
			product_name: testData?.name?.en,
			product_price: getFlatNumber(testData?.discountPrice),
			product_currency: 'BDT',
			product_quantity: patientCount
		});
	};

	const LabTestViewContentEvent = (payload) => {
		fbEvent('view_item', {
			content_ids: [payload?.id?.toString()],
			content_name: payload?.name?.en,
			content_type: 'web_lab_test',
			contents: [
				{
					...payload,
					quantity: 1
				}
			],
			currency: 'BDT',
			value: getFlatNumber(payload?.discountPrice)
		});

		firebaseEvent('view_item', {
			content_ids: payload?.id?.toString(),
			content_name: payload?.name?.en,
			content_type: 'web_lab_test',
			items: [
				{
					item_id: payload?.id,
					item_name: payload?.name?.en,
					price: getFlatNumber(payload?.discountPrice),
					quantity: 1
				}
			],
			currency: 'BDT',
			value: getFlatNumber(payload?.discountPrice)
		});

		claverTapEvent('ACT_VIEW_PRODUCT', {
			content_type: 'web_lab_test',
			product_id: payload?.id,
			product_name: payload?.name?.en,
			product_price: getFlatNumber(payload?.discountPrice),
			product_currency: 'BDT',
			product_quantity: 1
		});
	};

	const LabTestSearchEvent = (payload) => {
		fbEvent('Search', {
			search_string: payload.search,
			content_type: 'web_lab_test'
		});
		firebaseEvent('search', {
			search_term: payload.search,
			content_type: 'web_lab_test'
		});
		claverTapEvent('ACT_SEARCH_TERM', {
			search_term: payload.search,
			content_type: 'web_lab_test'
		});
	};

	const LabTestCheckoutEvent = (payload) => {
		fbEvent('InitiateCheckout', {
			content_type: 'web_lab_test',
			content_ids: payload?.items?.map((e) => e?.item?.id.toString()),
			contents: payload?.items?.map((e) => {
				return { ...e?.item, id: e?.item?.id, quantity: e?.patientCount };
			}),
			currency: 'BDT',
			num_items: payload?.itemCount,
			value: payload?.isHardCopyRequired
				? getFlatNumber(payload?.totalAmount)
				: getFlatNumber(payload?.totalAmountWithoutConveyance)
		});

		firebaseEvent('begin_checkout', {
			content_type: 'web_lab_test',
			currency: 'BDT',
			items: payload?.items,
			num_items: payload?.itemCount,
			value: payload?.isHardCopyRequired
				? getFlatNumber(payload?.totalAmount)
				: getFlatNumber(payload?.totalAmountWithoutConveyance)
		});

		claverTapEvent('ACT_BEGIN_CHECKOUT', {
			content_type: 'web_lab_test',
			items: JSON.stringify(payload?.items)
		});
	};

	const LabTestOrderPurchaseEvent = (payload) => {
		fbEvent('Purchase', {
			content_type: 'web_lab_test',
			content_ids: payload?.items?.map((e) => e?.id.toString()),
			contents: payload?.items?.map((e) => {
				return { ...e, id: e?.id, quantity: e?.patientCount };
			}),
			currency: 'BDT',
			num_items: payload?.itemCount,
			value: getFlatNumber(payload?.totalAmount)
		});

		firebaseEvent('purchase', {
			content_type: 'web_lab_test',
			currency: 'BDT',
			items: payload?.items,
			num_items: payload?.itemCount,
			value: getFlatNumber(payload?.totalAmount)
		});

		claverTapEvent('ACT_CHECKOUT_SUCCESS', {
			content_type: 'web_lab_test',
			items: JSON.stringify(payload?.items)
		});
	};

	return {
		LabTestAddToCartEvent,
		LabTestViewContentEvent,
		LabTestSearchEvent,
		LabTestCheckoutEvent,
		LabTestOrderPurchaseEvent
	};
};

export default useLabTestPixalEvent;

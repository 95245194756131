import { isEmpty } from 'lodash';
import Image from 'next/image';
import Link from 'next/link';
import { getImage } from './helper';
interface convertToTreeDataProps {
	items: any[];
}

export const convertToTreeData = (items: convertToTreeDataProps['items']) => {
	const map = new Map();
	const roots = [];

	items?.forEach((item: any) => {
		map.set(item.mi_id, {
			label: (
				<Link
					style={{
						textDecoration: 'none',
						color: 'inherit'
					}}
					href={item.mi_url}>
					{item.mi_name}
				</Link>
			),
			key: `key_${item.mi_id}`,
			children: [],
			weight: item.mi_weight,
			itemIcon: (
				<Image src={getImage(item.attachedFiles_mi_logo)} width={32} height={32} alt={item.mi_name} unoptimized />
			),
			mi_id: item.mi_id,
			mi_url: item.mi_url,
			mi_name: item.mi_name
		});
	});

	// Function to recursively attach children to their parent node
	const attachToParent = (item, map) => {
		const node = map.get(item.mi_id) as any;
		if (item.mi_parent_mi_id) {
			const parent = map.get(item.mi_parent_mi_id);
			if (parent) {
				// Add child node to parent, remove itemIcon and children key if empty
				const childNode = {
					label: <Link href={node.mi_url}>{node.mi_name}</Link>,
					key: node.mi_id.toString(),
					weight: node.mi_weight,
					...node,
					itemIcon: undefined,
					mi_name: node.mi_name
				};
				if (childNode.children && childNode.children.length === 0) {
					delete childNode.children; // Remove children key if empty
				}
				if (!isEmpty(childNode)) {
					parent.children = parent.children || [];
					parent.children.push(childNode);
				}
			}
		} else {
			// Add node to roots, remove children key if empty
			if (node.children && node.children.length === 0) {
				delete node.children; // Remove children key if empty
			}
			roots.push(node);
		}

		// If the node has its own children data, process them recursively
		if (item.children && Array.isArray(item.children)) {
			item.children.forEach((childItem) => attachToParent(childItem, map));
		}
	};

	// Start the recursive attachment process
	items?.forEach((item) => attachToParent(item, map));
	const sortTree = (nodes) => {
		nodes.sort((a, b) => a.weight - b.weight);
		nodes.forEach((node) => {
			if (node.children && node.children.length > 0) {
				sortTree(node.children);
			}
		});
	};
	sortTree(roots);

	return roots;
};
export const convertToTreeDataForMegaMenu = (items, parent_id) => {
	const map = new Map() as any;
	const roots = [];
	const generatelink = (item) => {
		return item;
	};

	// Adjust parent IDs and create nodes for each item
	const MenuItems = items.map((item) => {
		const newItem = {
			...item,
			mi_parent_mi_id: item.mi_parent_mi_id === parent_id ? 0 : item.mi_parent_mi_id,
			mi_name: (
				<Link prefetch={true} href={generatelink(item.mi_url)}>
					{item.mi_name}
				</Link>
			),
			children: [] // Initialize children array
		};
		map.set(newItem.mi_id, newItem);
		return newItem;
	});

	MenuItems.forEach((item: any) => {
		if (item.mi_parent_mi_id === 0) {
			roots.push(map.get(item.mi_id)); // These are root items
		} else if (map.has(item.mi_parent_mi_id)) {
			const parent = map.get(item.mi_parent_mi_id);
			// Attach only if parent is a root item
			if (parent.mi_parent_mi_id === 0) {
				parent.children.push(item);
			}
		}
	});

	// Sort function for tree nodes
	const sortTree = (nodes) => {
		nodes.sort((a, b) => a.mi_weight - b.mi_weight);
		nodes.forEach((node) => {
			if (node.children && node.children.length > 0) {
				sortTree(node.children);
			}
		});
	};

	sortTree(roots);

	return roots;
};

export const findParentKeys = (nodes, targetKey) => {
	let path = [] as any;
	const searchTree = (nodes, currentPath) => {
		for (const node of nodes) {
			const newPath = [...currentPath, node.key];

			if (node.mi_url === targetKey) {
				// If the target node is found, update the path and return
				path = newPath;
				return true;
			}

			if (node.children && node.children.length > 0) {
				// Continue searching in children
				if (searchTree(node.children, newPath)) {
					return true; // If found in children, stop searching
				}
			}
		}
		return false;
	};

	searchTree(nodes, []);
	return path; // Return the path as an array
};

export const convertToTreeDataForSideMenu = (items, parent_id) => {
	const map = new Map();
	const roots = [] as any;

	// Adjust parent IDs and create nodes for each item
	const MenuItems = items.map((item) => {
		const newItem = {
			...item,
			mi_parent_mi_id: item.mi_parent_mi_id === parent_id ? 0 : item.mi_parent_mi_id,
			children: [] // Initialize children array
		};
		map.set(newItem.mi_id, newItem);
		return newItem;
	});

	MenuItems.forEach((item) => {
		if (item.mi_parent_mi_id === 0) {
			roots.push({
				label: (
					<Link
						style={{
							textDecoration: 'none',
							color: 'inherit',
							width: '200px',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
							overflow: 'hidden'
						}}
						href={item.mi_url}>
						{item.mi_name}
					</Link>
				),
				key: item.mi_url,
				children: [],
				weight: item.mi_weight,
				itemIcon: (
					<Image
						src={getImage(item.attachedFiles_mi_logo)}
						width={32}
						height={32}
						alt={item.mi_name}
						unoptimized
					/>
				),
				mi_id: item.mi_id,
				...item
			}); // These are root items
		} else if (map.has(item.mi_parent_mi_id)) {
			const parent = map.get(item.mi_parent_mi_id);
			parent.children.push({
				label: (
					<Link
						style={{
							textDecoration: 'none',
							color: 'inherit',
							width: '220px',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
							overflow: 'hidden'
						}}
						href={item.mi_url}>
						{item.mi_name}
					</Link>
				),
				key: item.mi_url,
				children: [],
				weight: item.mi_weight,
				itemIcon: (
					<Image
						src={getImage(item.attachedFiles_mi_logo)}
						width={32}
						height={32}
						alt={item.mi_name}
						unoptimized
					/>
				),
				mi_id: item.mi_id,
				...item
			}); // Attach to parent, regardless of parent's level
		}
	});

	// Sort function for tree nodes
	const sortTree = (nodes) => {
		nodes.sort((a, b) => a.mi_weight - b.mi_weight);
		nodes.forEach((node) => {
			if (node.children.length > 0) {
				sortTree(node.children); // Recursively sort children
			} else {
				delete node.children; // Remove the children key if empty
			}
		});
	};

	sortTree(roots);

	return roots;
};

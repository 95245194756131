'use client';
import ShopByCategoriesButton from '@/components/atoms/ShopByCategoriesButton';
import useAuth from '@/contexts/AuthProvider';
import useProduct from '@/contexts/ProductProvider';
import { convertToTreeData, findParentKeys } from '@/services/utils/MenuTreeBuilder';
import Menu from 'ar-menu';
import { get } from 'lodash';
import { usePathname } from 'next/navigation';
import { memo, useEffect, useState } from 'react';
import { MdChevronRight } from 'react-icons/md';

interface MenuItemProps {
	label: string;
	key: string;
	submenu?: MenuItemProps[];
}

const Sidebar = ({ menuData }) => {
	const { menus } = useAuth();
	const [openKeys, setOpenKeys] = useState<string[]>([]);
	const [menuTree, setMenuTree] = useState<any[]>([]);
	const [windowSize, setWindowSize] = useState<any>();
	const pathname = usePathname();
	const {
		showHamburger,
		setShowHamburger
	} = useProduct();
	useEffect(() => {
		const finalMenuData = get(menuData, 'sideMenu.data', [])?.concat(menus || []);
		const sideMenu = convertToTreeData(finalMenuData);
		setMenuTree(sideMenu);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menus, menuData]);

	const shouldShowHeader =
		!pathname.startsWith('/blog') && !pathname.startsWith('/jobs') && !pathname.startsWith('/search')&& !pathname.startsWith('/maintenance');

	useEffect(() => {
		const foundNode = findParentKeys(menuTree, pathname);
		if (foundNode.length > 0) {
			setOpenKeys(foundNode);
		} else {
			setOpenKeys([]);
		}
		if(windowSize < 1000){
			setShowHamburger(false)
		}
	
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname, menuTree]);


	useEffect(() => {
		const handleResize = () => {
			setWindowSize(window.innerWidth);
		
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	// !pathname.startsWith("/lab-test");
	if (!shouldShowHeader) {
		return null;
	}
	const lastOpenKey = openKeys[openKeys.length - 1];


	return (
		<>
			{/* <h1 className='m-20'>Show</h1> */}
			{
				showHamburger &&  <div className={'sidebar_wrap'} style={{

					display:  showHamburger ? 'block' : "none",

				}}>


					<div className={'sidebar'}>
						<ShopByCategoriesButton className={'side_nav__category'} />


						<Menu
							prefixCls='arogga-sidebar'
							mode='inline'
							openKeys={openKeys}
							selectedKeys={[lastOpenKey]}
							onSelect={(e) => {
								console.log(e);
							}}
							onOpenChange={(e) => {
								setOpenKeys(e);
							}}
							expandIcon={<MdChevronRight className={'arogga-sidebar-submenu-arrow'} size={24} />}
							items={menuTree}
						/>


					</div>
				</div>
			}


		</>

	);
};
export default memo(Sidebar);

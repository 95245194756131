'use client';

import { Field } from 'ar-form';
import React, { useState } from 'react';
import Input from '../Input';
import Radio from '../Radio';
import Select from '../Select';
import Switch from '../Switch';
import TreeSelect from '../TreeSelect';
import UploadButton from '../UploadButton';
import { map } from 'lodash';

interface FromInputProps {
	name: string;
	rules?: any[]; // Replace 'any' with a more specific type for your validation rules
	label: React.ReactNode | React.ReactNode[];
	placeholder: string;
	[key: string]: any;
}

interface ErrorProps {
	children: React.ReactNode | React.ReactNode[];
}

const Error: React.FC<ErrorProps> = ({ children }) => {
	if (!children) {
		return null; // Don't render anything if there are no errors
	}
	const errors = Array.isArray(children) ? children : [children];
	return (
		<div className='text-14 my-6' style={{ color: 'red' }}>
			{errors.map((error, index) => (
				<div key={index}>{error}</div>
			))}
		</div>
	);
};

const FormInput: React.FC<FromInputProps> = ({ name, rules, label, placeholder, ...rest }) => {
	const isRequired = rules && rules.some((rule) => rule.required);

	return (
		<div style={{ width: '100%', marginBottom: 20 }}>
			<label className='text-grey900 fw-500 text-16 mb-20' htmlFor={name}>
				{label} {isRequired && <span style={{ color: 'red' }}>*</span>}
			</label>
			<Field name={name} rules={rules}>
				{(control, meta) => (
					<div className='mt-10'>
						<Input type='text' placeholder={placeholder} {...control} {...rest} />
						<Error>{meta.errors}</Error>
					</div>
				)}
			</Field>
		</div>
	);
};

const FormSelect: React.FC<FromInputProps> = ({ name, rules, label, placeholder, options, onChange }) => {
	const isRequired = rules && rules.some((rule) => rule.required);

	return (
		<div style={{ width: '100%', marginBottom: 20 }}>
			<label className='text-grey900 fw-500 text-16 mb-20' htmlFor={name}>
				{label} {isRequired && <span style={{ color: 'red' }}>*</span>}
			</label>
			<Field name={name} rules={rules}>
				{(control, meta) => (
					<div className='mt-10'>
						<Select
							size='large'
							options={options || []}
							placeholder={placeholder}
							searchable={true}
							defaultValue={control.value}
							value={control.value}
							onChange={(value) => {
								control.onChange(value);
								onChange && onChange(value);
							}}
						/>
						<Error>{meta.errors}</Error>
					</div>
				)}
			</Field>
		</div>
	);
};
const FormTreeSelect: React.FC<FromInputProps> = ({
	name,
	rules,
	label,
	placeholder,
	options,
	onChange,
	showCount
}) => {
	const isRequired = rules && rules.some((rule) => rule.required);

	return (
		<div style={{ width: '100%', marginBottom: 20 }}>
			<label className='text-grey900 fw-500 text-16 mb-20' htmlFor={name}>
				{label} {isRequired && <span style={{ color: 'red' }}>*</span>}
			</label>
			<Field name={name} rules={rules}>
				{(control, meta) => (
					<div className='mt-10'>
						<TreeSelect
							data={options}
							showCount={showCount}
							value={control.value}
							placeholder={placeholder}
							onChange={(value) => {
								control.onChange(value);
								onChange && onChange(value);
							}}
						/>
						<Error>{meta.errors}</Error>
					</div>
				)}
			</Field>
		</div>
	);
};

const FormRadioButton: React.FC<FromInputProps> = ({ name, rules, label, placeholder, options, ...rest }) => {
	const isRequired = rules && rules.some((rule) => rule.required);

	return (
		<div style={{ width: '100%', marginBottom: 20 }}>
			<label className='text-grey900 fw-500 text-16 mb-20' htmlFor={name}>
				{label} {isRequired && <span style={{ color: 'red' }}>*</span>}
			</label>
			<Field name={name} rules={rules}>
				{(control, meta) => (
					<div className='mt-10'>
						<Radio.Group
							name={name}
							defaultValue={control.value}
							options={options || []}
							onChange={(e) => {
								control.onChange(e.target.value);
							}}>
							{rest.children}
						</Radio.Group>
						<Error>{meta.errors}</Error>
					</div>
				)}
			</Field>
		</div>
	);
};

const FormSwitch: React.FC<FromInputProps> = ({ name, rules, label, placeholder, options, ...rest }) => {
	const isRequired = rules && rules.some((rule) => rule.required);

	return (
		<div
			style={{
				width: '100%',
				marginBottom: 20,
				display: 'flex',
				alignItems: 'baseline',
				gap: 20
			}}>
			<label className='text-grey900 fw-500 text-16 mb-20' htmlFor={name}>
				{label} {isRequired && <span style={{ color: 'red' }}>*</span>}
			</label>
			<Field name={name} rules={rules}>
				{(control, meta) => (
					<div className='mt-10'>
						<Switch
							checked={control.value ? true : false}
							onChange={(value) => {
								control.onChange(value);
							}}
						/>

						<Error>{meta.errors}</Error>
					</div>
				)}
			</Field>
		</div>
	);
};

const FromFile: React.FC<FromInputProps> = ({ name, rules, label, placeholder, accept, ...rest }) => {
	const [hasFormFile, setHasFormFile] = useState()

	const isRequired = rules && rules.some((rule) => rule.required);

	const handleHasFormFile = (data:any) => {
		setHasFormFile(data)
	}
	
	return (
		<div
			style={{
				width: '100%',
				marginBottom: 20
			}}>
			<label className='text-grey900 fw-500 text-16 mb-20' htmlFor={name}>
				{label} {isRequired && <span style={{ color: 'red' }}>*</span>}
			</label>
			<Field name={name} rules={hasFormFile ? [] : rules}>
				{(control, meta) => (
					<div className='mt-10'>
						<Input
							accept={accept}
							type='file'
							placeholder={placeholder}
							onChange={(e: any) => {
								control.onChange(e.target.files[0]);
							}}
							handleHasFormFile={handleHasFormFile}
						/>
						<Error>{meta.errors}</Error>
					</div>
				)}
			</Field>
		</div>
	);
};

const FromFileBox: React.FC<
	FromInputProps & {
		fileType: 'base64' | 'raw';
		isMultiple?: boolean;
	}
> = ({ name, rules, label, placeholder, fileType, isMultiple, ...rest }) => {
	const isRequired = rules && rules.some((rule) => rule.required);
	const handleFileUpload = (files: any, control) => {
		if (isMultiple) {
			const data = [];
			map(files, (file) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					const base64 = reader.result;
					data.push({
						title: file.name,
						src: base64
					});
				};
			});

			control.onChange(data);
			return;
		}

		const file = files[0];

		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			const base64 = reader.result;
			control.onChange(base64);
		};
		reader.onerror = (error) => {
			console.log('Error: ', error);
		};
	};

	return (
		<div
			style={{
				width: '100%',
				marginBottom: 20
			}}>
			<label className='text-grey900 fw-500 text-16 mb-20' htmlFor={name}>
				{label} {isRequired && label && <span style={{ color: 'red' }}>*</span>}
			</label>
			<Field name={name} rules={rules}>
				{(control, meta) => (
					<div className='mt-10'>
						<UploadButton
							isMultiple={isMultiple}
							accept={rest.accept}
							label={
								<>
									{placeholder || 'Upload Drug License'}
									{isRequired && <span className='text-error'>*</span>}
									<span className='text-12 text-grey600 fw-400 ml-5'>
										{rest.allowTypesText || '(PDF or JPEG)'}
									</span>
								</>
							}
							control={control}
							onChooseFile={(files) => {
								handleFileUpload(files, control);
							}}
						/>
						<Error>{meta.errors}</Error>
					</div>
				)}
			</Field>
		</div>
	);
};

export { FormInput, FormRadioButton, FormSelect, FormSwitch, FormTreeSelect, FromFile, FromFileBox };

import { MdCheckCircle } from 'react-icons/md';
import style from './ListItem.module.css';

const ListItem = ({ name }) => {
	return (
		<div className={style.testDetails_lists_text}>
			<MdCheckCircle
				size={24}
				style={{
					color: 'var(--ltPrimary)',
				}}
			/>
			<p className='ltbody_lg__regular'>{name}</p>
		</div>
	);
};

export default ListItem;
